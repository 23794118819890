import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/hUE0-MOnbtg"
    bibleGroupSrc="https://www.docdroid.net/8hZfq9I/bible-group-homework-8-9-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/29369035"
  >
    <SEO title="Hosea - Now What?" />
  </Layout>
)

export default SermonPost
